<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img :src="imgfile" class="c-avatar-img" alt="Sua foto">
        </div>
        <div class="c-empresa">
          {{ $session.get('empresa') }}
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>{{ $session.get('first_name') }}</strong>
    </CDropdownHeader>
    <CDropdownItem @click="meusdados()">
      <CIcon name="cil-user" /> Perfil
    </CDropdownItem>
    <CDropdownItem @click="minhasopcoes()">
      <CIcon name="cil-settings" /> Opções
    </CDropdownItem>
    <CDropdownDivider/>
    <CDropdownItem @click="saindo()">
      <CIcon name="cil-account-logout" /> Sair
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      itemsCount: 1,
      imgfile: '/img/semfoto.jpg'
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.imguser()
  },
  mounted: function () {
    this.$nextTick(function () {
      this.imguser()
    })
  },
  methods: {
    meusdados () {
      this.$router.push('/configuracoes/usuarios/editar/' + this.$session.get('id'))
    },
    minhasopcoes () {
      this.$router.push('/configuracoes/opcoes')
    },
    imguser () {
      this.campo('gp_User', 'usr_img', 'api_ID=' + this.$session.get('id')).then(
        (response) => {
          if (response) {
            this.imgfile = response
          }
        },
        (response) => {}
      )
    },
    saindo () {
      this.$http.delete(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.$session.destroy()
        document.location.href='https://www.prolucro.com.br/login'
        // this.$router.push('/login')
      }, (response) => {
        this.$session.destroy()
        document.location.href='https://www.prolucro.com.br/login'
        // this.$router.push('/login')
      })
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>