<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://prolucro.com.br/sistema-de-gestao/gerapreco" target="_blank">ProLucro Gera Preço</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} ProLucro Sistemas / Argument Tecnologia</span>
    </div>
    <div class="ml-auto">
      <a href="https://www.prolucro.com.br/wp-content/uploads/2020/09/Sistemas_TermosdeUso_2020-05-01.pdf" target="_blank">Termos de Uso e Política de Privacidade</a>&nbsp;|&nbsp; 
      <span class="mr-1">Versão</span>
      <a href="https://prolucro.com.br/release-notes" target="_blank">1.5.4</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
