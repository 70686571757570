<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CImg  
        class="d-block" 
        name="logo" 
        src="/img/prolucro.png"
        height="45px"
      />
    </CSidebarBrand>

    <ul class="c-sidebar-nav h-100 ps" style="position: relative;">
    <CRenderFunction flat :content-to-render="new_nav"/>
    </ul>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
    
  </CSidebar>
</template>

<script>
import nav from './_nav'

export default {
  name: 'TheSidebar',
  nav,
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  },
  data () {
    return {
      new_nav: [{
        name: 'CSidebarNav',
        _children: []
      }],
      tabelasCustom: []
    }
  },
  created: function () {
    /* Regras para a navegação (beta) */
    // this.new_nav = this.$options.nav
    this.tabelasCustom = {
      'tabela1' : '1. Venda Direta',
      'tabela2' : '2. Revendedor',
      'tabela3' : '3. Distribuidor',
      'tabela1_ligada' : true,
      'tabela2_ligada' : true,
      'tabela3_ligada' : true,
    }
    this.listar('gp_Metadados', 'id', '(module LIKE opcoes_tabelas)').then(
      (m) => {
        for (var i = 0; i < m.length; i++) {
          if (m[i].string === 'tabela1') {
            this.tabelasCustom.tabela1 = '1. ' + m[i].description
          }
          if (m[i].string === 'tabela2') {
            this.tabelasCustom.tabela2 = '2. ' + m[i].description
          }
          if (m[i].string === 'tabela3') {
            this.tabelasCustom.tabela3 = '3. ' + m[i].description
          }
          if (m[i].string === 'tabela1_ligada') {
            this.tabelasCustom.tabela1_ligada = true
          }
          if (m[i].string === 'tabela2_ligada') {
            this.tabelasCustom.tabela2_ligada = (m[i].description === '1')
          }
          if (m[i].string === 'tabela3_ligada') {
            this.tabelasCustom.tabela3_ligada = (m[i].description === '1')
          }
        }
        this.montaMenu()
      },
      (m) => {
        this.montaMenu()
      }
    )
  },
  methods: {
    montaMenu: function () {
      // Renomeia os itens de menu customizados
      let removendo = []
      for (var i = 0; i < this.$options.nav[0]['_children'].length; i++) {
        removendo = []
        // Para cada item de menu, se houver subitens...
        if (typeof this.$options.nav[0]['_children'][i]['items'] !== 'undefined') {
          // Para cada subitem...
          for (var j = 0; j < this.$options.nav[0]['_children'][i]['items'].length; j++) {
            // Se tiver a opção de customização...
            if (typeof this.$options.nav[0]['_children'][i]['items'][j] !== 'undefined')
            if (typeof this.$options.nav[0]['_children'][i]['items'][j]['custom'] !== 'undefined') {
              // Verifica se é pra mostrar ou não...
              if (this.tabelasCustom[this.$options.nav[0]['_children'][i]['items'][j]['custom'] + '_ligada']) {
                // Ta ligada, é pra exibir com o nome personalizado
                this.$options.nav[0]['_children'][i]['items'][j]['name'] = this.tabelasCustom[this.$options.nav[0]['_children'][i]['items'][j]['custom']]
              } else {
                // Senão, deleta a entrada de menu
                removendo.push(j)
              }
            }
          }
          if (removendo.length > 0) {
            if (removendo.length > 1) {
              this.$options.nav[0]['_children'][i]['items'].splice(1, 2)
            } else {
              this.$options.nav[0]['_children'][i]['items'].splice(removendo[0], 1)
            }
          }
        }
      }
      // Monta o menu
      for (var i = 0; i < this.$options.nav[0]['_children'].length; i++) {
        if (this.permissao(this.$options.nav[0]['_children'][i]['regra'])) {
          this.new_nav[0]['_children'].push(this.$options.nav[0]['_children'][i])
        }
      }
    }
  }
}
</script>
