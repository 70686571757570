export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavDropdown',
        name: 'Tabelas de Preços',
        icon: 'cib-server-fault',
        regra: 'DASHBOARD',
        items: [    
          {
            name: '1. Venda Direta',
            to: '/dashboard/1',
            icon: 'cil-grid',
            regra: 'DASHBOARD',
            custom: 'tabela1'
          },
          {
            name: '2. Revendedor',
            to: '/dashboard/2',
            icon: 'cil-grid',
            regra: 'DASHBOARD',
            custom: 'tabela2'
          },
          {
            name: '3. Distribuidor',
            to: '/dashboard/3',
            icon: 'cil-grid',
            regra: 'DASHBOARD',
            custom: 'tabela3'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Gera Preço',
        icon: 'cil-calculator',
        regra: 'PRECIFICADOR',
        items: [    
          {
            name: '1. Venda Direta',
            to: '/gerapreco/1',
            icon: 'cil-wallet',
            regra: 'PRECIFICADOR',
            custom: 'tabela1'
          },
          {
            name: '2. Revendedor',
            to: '/gerapreco/2',
            icon: 'cil-wallet',
            regra: 'PRECIFICADOR',
            custom: 'tabela2'
          },
          {
            name: '3. Distribuidor',
            to: '/gerapreco/3',
            icon: 'cil-wallet',
            regra: 'PRECIFICADOR',
            custom: 'tabela3'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Custos Diretos',
        icon: 'cil-money',
        regra: 'PRECIFICADOR',
        items: [    
          {
            name: 'Produtos',
            to: '/precificador',
            icon: 'cil-clone',
            regra: 'PRECIFICADOR',
          },
          {
            name: 'Preparos',
            to: '/preparo',
            icon: 'cil-flip-to-back',
            regra: 'PRECIFICADOR',
          }
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Anotações',
        to: '/anotacoes',
        icon: 'cil-pencil',
        regra: 'ANOTACAO',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Consultoria Web',
        to: '/faq',
        icon: 'cil-laptop',
        regra: 'SUPORTE',
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Cadastros',
        icon: 'cil-notes',
        regra: 'TABELAS',
        items: [
          {
            name: 'Materiais',
            to: '/tabelas/materia-prima',
            icon: 'cil-3d',
            regra: 'TABELAS_MATERIAPRIMA'
          },
          {
            name: 'Embalagens',
            to: '/tabelas/embalagem',
            icon: 'cib-codesandbox',
            regra: 'TABELAS_EMBALAGEM'
          },
          {
            name: 'Serviços de Terceiros',
            to: '/tabelas/servico-terceiro',
            icon: 'cil-truck',
            regra: 'TABELAS_TERCEIRO'
          },
          {
            name: 'Equipamentos',
            to: '/tabelas/equipamento',
            icon: 'cil-monitor',
            regra: 'TABELAS_EQUIPAMENTO'
          },
          {
            name: 'Mão de Obra',
            to: '/tabelas/mao-de-obra',
            icon: 'cil-people',
            regra: 'TABELAS_MAODEOBRA'
          },
          {
            name: 'Câmbio',
            to: '/tabelas/cambio',
            icon: 'cil-dollar',
            regra: 'TABELAS_MATERIAPRIMA'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Configurações',
        icon: 'cil-settings',
        regra: 'CONFIGURACOES',
        items: [
          /* {
            name: 'Opções',
            to: '/configuracoes/opcoes',
            icon: 'cil-settings',
            regra: 'CONFIGURACOES_IMPOSTOS'
          }, */
          {
            name: 'Usuários',
            to: '/configuracoes/usuarios/listar',
            icon: 'cil-user',
            regra: 'CONFIGURACOES_USUARIOS'
          },
          {
            name: 'Regime Tributário',
            to: '/configuracoes/impostos',
            icon: 'cil-institution',
            regra: 'CONFIGURACOES_IMPOSTOS'
          },
          {
            name: 'Encargos Trabalhistas',
            to: '/configuracoes/encargos',
            icon: 'cil-wc',
            regra: 'CONFIGURACOES_ENCARGOS'
          },
          {
            name: 'Unidades e Conversores',
            to: '/configuracoes/unidades',
            icon: 'cil-balance-scale',
            regra: 'CONFIGURACOES_UNIDADES'
          },
          {
            name: 'Linha de Produtos',
            to: '/configuracoes/linha_produtos',
            icon: 'cil-list-numbered',
            regra: 'CONFIGURACOES_LINHAPRODUTOS'
          },
          {
            name: 'Tabelas',
            to: '/configuracoes/tabelas',
            icon: 'cil-columns',
            regra: 'CONFIGURACOES_LINHAPRODUTOS'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Administrativo',
        icon: 'cil-lock-locked',
        regra: 'ADMINISTRATIVO',
        items: [
          {
            name: 'Usuários',
            to: '/admin/usuarios/listar',
            icon: 'cil-user',
            regra: 'ADMINISTRATIVO_USUARIOS'
          },
          {
            name: 'Empresas',
            to: '/admin/empresas/listar',
            icon: 'cib-linkedin',
            regra: 'ADMINISTRATIVO_GRUPOS'
          },
          {
            name: 'Grupos',
            to: '/admin/grupos/listar',
            icon: 'cil-people',
            regra: 'ADMINISTRATIVO_GRUPOS'
          },
          {
            name: 'Regras',
            to: '/admin/regras/listar',
            icon: 'cil-shield-alt',
            regra: 'ADMINISTRATIVO_REGRAS'
          },
          {
            name: 'Usuários & Grupos',
            to: '/admin/usuarios_grupo/listar',
            icon: 'cil-list',
            regra: 'ADMINISTRATIVO_USUARIOSGRUPOS'
          },
          {
            name: 'Clientes & Áreas',
            to: '/admin/clientes_areas/listar',
            icon: 'cil-list',
            regra: 'ADMINISTRATIVO_CLIENTESAREAS'
          },
          {
            name: 'Grupos & Regras',
            to: '/admin/regras_grupo/listar',
            icon: 'cil-list',
            regra: 'ADMINISTRATIVO_GRUPOSREGRAS'
          }
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Tutoriais',
        to: '/ajuda',
        icon: 'cil-life-ring',
        regra: 'SUPORTE',
      }
      /* {
        _name: 'CSidebarNavItem',
        name: 'Documentação',
        href: 'https://prolucro.com.br/',
        icon: { name: 'cil-cloud-download', class: 'text-white' },
        _class: 'bg-success text-white',
        target: '_blank'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Solicitar Consultoria',
        href: 'https://www.prolucroconsultoria.com.br/',
        icon: { name: 'cil-layers', class: 'text-white' },
        _class: 'bg-danger text-white',
        target: '_blank'
      } */
    ]
  }
]